<script setup lang="ts">
    import LinkButton from '@/components/LinkButton.vue';
    import RenderResultErrors from '@/components/RenderResultErrors.vue';
    import { useDialogWidth } from '@/shared/hooks';
    import dayjs from 'dayjs';
    import { computed, toRef } from 'vue';
    import { useOrderRun, useOrderRunStatus } from '../hooks';
    import OrderRunProgressBar from './OrderRunProgressBar.vue';
    import OrderRunStatusChip from './OrderRunStatusChip.vue';

    type Props = {
        orderRunId: string;
    };

    const props = defineProps<Props>();

    const modelValue = defineModel<boolean>();
    const dialogWidth = useDialogWidth({ md: 0.65, lg: 0.65, xl: 0.65 });

    const { isLoading: orderLoading, error: orderError, orderRun } = useOrderRun(toRef(() => props.orderRunId));
    const { runStatusColor, runStatusIcon, runStatusDesc } = useOrderRunStatus(
        toRef(() => orderRun.value),
    );

    const daysRemaining = computed(() => {
        if (!orderRun.value) return 0;

        return dayjs(orderRun.value.endDate).diff(dayjs(), 'days');
    });

    function close() {
        modelValue.value = false;
    }
</script>
<template>
    <v-dialog v-model="modelValue" :width="dialogWidth" scrollable>
        <v-card v-if="orderRun" color="surface-container" rounded="md">
            <v-card-title class="pb-0 d-flex align-center justify-space-between">
                <div class="font-weight-medium">
                    <span class="text-h3 emphasis-8">
                        Order Run:
                    </span>
                    <span class="text-h2 text-blue-darken-2">
                        {{ orderRun.orderName }}
                    </span>
                    <LinkButton class="ml-2" @click="$triggerDialog('viewOrder', { orderId: orderRun.orderId })">
                        View Order
                    </LinkButton>
                </div>
                <span>
                    <OrderRunStatusChip v-if="orderRun" :run="orderRun" size="default" />
                </span>
            </v-card-title>
            <v-card-subtitle class="d-flex mb-1 pt-1 gap-sm">
                <span class="text-h4 font-weight-medium">
                    {{ $filters.date(orderRun.startDate) }} - {{ $filters.date(orderRun.endDate) }} ({{ daysRemaining }}
                    days remaining)</span>
                <v-spacer />
                <div class="flex-grow-1 d-flex align-center">
                    <OrderRunProgressBar :order-run="orderRun" show-text />
                </div>
                <span class="text-h4">
                    <!-- <b>${{ $filters.number(order.totalPrice / order.flightTimeMonths) }}</b>/mo -->
                </span>
            </v-card-subtitle>
            <v-alert
                variant="tonal"
                class="text-h4"
                rounded="0"
                density="comfortable"
                :icon="runStatusIcon"
                :color="runStatusColor"
            >
                {{ runStatusDesc }}
            </v-alert>
            <v-divider thickness="2px" />
            <v-card-text class="bg-surface d-flex flex-column">
                <div class="d-flex flex-wrap gap-md">
                    <v-btn
                        class="text-capitalize"
                        variant="tonal"
                        color="primary"
                        density="comfortable"
                        rounded="sm"
                        prepend-icon="mdi-arrow-top-right-thick"
                        @click="$notImplemented('View Company')"
                    >
                        {{ orderRun.companyName }}
                    </v-btn>
                </div>

                <div class="my-2">
                    <div class="text-h3 font-weight-medium">
                        Industries:
                    </div>
                    <div class="d-flex flex-wrap gap-sm pt-2">
                        <!-- <v-chip v-for="industry in distinctIndustries" :key="industry" color="grey">
                            <IndustrySectorIcon :sector="industry" size="h4" />
                            <span class="pl-1">
                                {{ industry }}
                            </span>
                        </v-chip> -->
                    </div>
                </div>

                <IfCapability capability="campaign:manage:any">
                    <div class="my-2">
                        <div class="text-h3 font-weight-medium">
                            Error State:
                        </div>
                        <div class="d-flex flex-wrap gap-sm pt-2">
                            {{ orderRun.errorState }}
                        </div>
                    </div>
                </IfCapability>

                <v-divider class="my-3" thickness="2" />
                <slot name="content" />
            </v-card-text>
            <slot name="actions" :close="close">
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="grey" size="large" @click="close">
                        Close
                    </v-btn>
                </v-card-actions>
            </slot>

            <RenderResultErrors :result="orderError" />
        </v-card>
        <v-card v-else>
            <v-card-title class="pb-0 d-flex align-center justify-space-between">
                <v-skeleton-loader class="text-h3 emphasis-8" width="100%" type="heading" />
            </v-card-title>
            <v-card-text>
                <v-skeleton-loader class="text-h4" type="card" />
                <LoaderOverlay :active="orderLoading" />
            </v-card-text>
            <v-card-actions>
                <v-skeleton-loader type="button" width="6rem" />
                <v-btn color="grey" size="large" @click="close">
                    Close
                </v-btn>
            </v-card-actions>
            <RenderResultErrors :result="orderError" />
        </v-card>
    </v-dialog>
</template>
