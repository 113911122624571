<script setup lang="ts">
    import { toRef } from 'vue';

    import type { SimpleOrderRunResponse } from '../api/orderRunsApiResponseModels';
    import { useOrderRunStatus } from '../hooks';

    type Props = {
        run: SimpleOrderRunResponse;
        size?: 'x-small' | 'small' | 'default' | 'large' | 'x-large';
    };

    const props = withDefaults(defineProps<Props>(), {
        size: 'small',
    });

    const { runStatusColor, runStatusIcon, runStatusText, runStatusDesc } = useOrderRunStatus(toRef(() => props.run));
</script>

<template>
    <v-tooltip v-if="runStatusDesc" location="top" content-class="bg-surface-container-highest">
        <template v-slot:activator="{ props: activatorProps }">
            <v-chip :color="runStatusColor" :prepend-icon="runStatusIcon" :size="size" v-bind="activatorProps">
                {{ runStatusText }}
            </v-chip>
        </template>
        <span>{{ runStatusDesc }}</span>
    </v-tooltip>
    <v-chip v-else :color="runStatusColor" :prepend-icon="runStatusIcon" :size="size">
        {{ runStatusText }}
    </v-chip>
</template>
