<script setup lang="ts">
    import { Text } from '@/components';
    import LoaderOverlay from '@/components/LoaderOverlay.vue';
    import RenderResultErrors from '@/components/RenderResultErrors.vue';
    import { useCommand } from '@/features/api/hooks';
    import { useDialogWidth } from '@/shared/hooks';
    import { computed, toRef } from 'vue';
    import SmallInfoBox from './SmallInfoBox.vue';

    import CreativesDownloadButton from '@/features/creatives/components/CreativesDownloadButton.vue';
    import dayjs from 'dayjs';
    import { toast } from 'vuetify-sonner';
    import { useOrder } from '../hooks';

    interface Props {
        orderId: string;
    }

    const props = defineProps<Props>();
    const modelValue = defineModel<boolean>();

    const { isLoading: orderLoading, order, error: orderError } = useOrder(toRef(() => props.orderId));

    const { isLoading: actionLoading, mutateAsync, error: mutateError } = useCommand('orders/CREATE_LINKED_RUN', {
        invalidates: ['orders/GET_ORDER', 'campaigns/LIST_CAMPAIGNS', 'orders/LIST_ORDERS'],
        onSettled(data, error) {
            if (!error) {
                modelValue.value = false;
            }
        },
    });

    const error = computed(() => orderError.value || mutateError.value);
    const dialogWidth = useDialogWidth({ md: 0.9, lg: 0.9, xl: 1 });
    const downloadName = computed(() => {
        if (!order.value) return '';

        return `creatives--${order.value.companyName}--${order.value.name}`;
    });

    const orderName = computed(() => {
        return `${order.value?.companyName} - ${order.value?.name}`;
    });

    const orderStartDate = computed(() => {
        if (!order.value) return null;

        return dayjs(order.value?.startDate).add(1, 'day').toISOString();
    });

    async function createRun() {
        await mutateAsync({
            params: {
                orderId: props.orderId,
            },
        });
    }

    async function copyOrderId() {
        if (!order.value) return;
        await copy(order.value.id);
        toast.info('Order Number copied to clipboard');
    }

    async function copyOrderName() {
        if (!order.value) return;
        await copy(orderName.value);
        toast.info('Order Name copied to clipboard');
    }

    async function copy(value: string) {
        await navigator.clipboard.writeText(value);
    }
</script>

<template>
    <v-dialog v-model="modelValue" persistent :max-width="dialogWidth" content-class="v-row flex-row">
        <v-col cols="12" md="8" lg="8">
            <v-card color="surface-container">
                <v-card-title class="d-flex align-center justify-space-between gap-md pt-2">
                    <span class="text-h2">
                        Create Linked Run
                    </span>
                    <v-btn
                        class="text-capitalize"
                        variant="tonal"
                        color="primary"
                        density="comfortable"
                        rounded="sm"
                        prepend-icon="mdi-arrow-top-right-thick"
                        @click="$triggerDialog('viewOrder', { orderId: orderId })"
                    >
                        {{ order?.name }}
                    </v-btn>
                </v-card-title>
                <v-divider thickness="2px" />
                <v-card-text>
                    <v-row>
                        <v-col v-if="orderLoading" cols="12">
                            <v-skeleton-loader type="heading" />
                            <v-skeleton-loader type="heading" />
                        </v-col>
                        <v-col v-else cols="12">
                            <div class="d-flex align-center gap-sm mb-2">
                                <Text :char-width="14">Order Number: </Text>
                                <v-card variant="flat" rounded="sm">
                                    <v-card-text class="py-3 d-flex gap-md">
                                        <span>{{ order?.id }}</span>
                                        <v-icon icon="mdi-content-copy" title="Copy" @click="copyOrderId" />
                                    </v-card-text>
                                </v-card>
                            </div>
                            <div class="d-flex align-center gap-sm">
                                <Text :char-width="14">Order Name: </Text>
                                <v-card variant="flat" rounded="sm">
                                    <v-card-text class="py-3 d-flex gap-md">
                                        <span>{{ orderName }}</span>
                                        <v-icon icon="mdi-content-copy" title="Copy" @click="copyOrderName" />
                                    </v-card-text>
                                </v-card>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div class="text-h3 font-weight-medium">Instructions :</div>
                            <ol>
                                <li>Open AdSense and create a new order.</li>
                                <li>
                                    Paste the <Text inline color="secondary">Order Number</Text>
                                    above into the <Text inline color="secondary">Order Number</Text>
                                    field.
                                </li>
                                <li>
                                    <Text inline color="tertiary">Complete</Text>
                                    the order creation process using the info in the
                                    <Text inline color="secondary">Order Info</Text>
                                    panel
                                </li>
                                <li>
                                    In AdSense, <Text inline color="tertiary">Approve</Text>
                                    and <Text inline color="tertiary">Activate</Text>
                                    the new order.
                                </li>
                                <li>
                                    Once the AdSense Order has been <Text inline color="tertiary">Created</Text>
                                    , <Text inline color="tertiary">Approved</Text>
                                    , and
                                    <Text inline color="tertiary">Activated</Text>
                                    : Click the button below to create the Run and link it to AdSense.
                                </li>
                            </ol>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center">
                            <v-btn
                                rounded="md"
                                size="large"
                                color="primary"
                                variant="flat"
                                class="text-capitalize"
                                :loading="actionLoading"
                                @click="createRun"
                            >
                                Create Run
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="grey" size="large" @click="() => (modelValue = false)">
                        Cancel
                    </v-btn>
                </v-card-actions>
                <RenderResultErrors :result="error" />
                <LoaderOverlay :active="orderLoading" />
            </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="4">
            <v-card color="surface-container" class="h-100">
                <v-card-title class="text-center pt-2">
                    <span class="text-h2">
                        Order Info
                    </span>
                </v-card-title>
                <v-divider thickness="2px" />
                <v-card-text class="d-flex flex-column gap-sm">
                    <SmallInfoBox title="Start:">
                        {{ $filters.date(orderStartDate) }} 12:00 AM
                    </SmallInfoBox>
                    <SmallInfoBox title="End:">
                        {{ $filters.date(order?.endDate) }} 11:59 PM
                    </SmallInfoBox>
                    <SmallInfoBox title="Total Plays:" :value="Math.floor(order?.totalPlays || 0)">
                        {{ $filters.number(order?.totalPlays) }}
                    </SmallInfoBox>
                    <SmallInfoBox title="Creatives:">
                        Download
                        <CreativesDownloadButton
                            v-if="order"
                            density="compact"
                            content-class="my-n1 ml-1"
                            :creative-ids="order?.creativeIds"
                            :file-name="downloadName"
                        />
                    </SmallInfoBox>
                </v-card-text>
            </v-card>
        </v-col>
    </v-dialog>
</template>
