<script setup lang="ts">
    import { useBudgetedOrderCostsAndPlays } from '../../hooks';

    import { useCommand } from '@/features/api/hooks';
    import SmallCreativeCard from '@/features/creatives/components/SmallCreativeCard.vue';
    import SmallScreenCard from '@/features/screens/components/SmallScreenCard.vue';
    import { AdDurationDropdown } from '../inputs';

    import { DatePicker, RenderResultErrors } from '@/components';
    import { MaskedMoneyInput } from '@/components/inputs';
    import { useCompany, useCompanySectors } from '@/features/companies/hooks';
    import { useEditableOrder } from '@/features/orders/hooks';
    import BillingMatrix from '@/features/pricing/components/BillingMatrix.vue';
    import { SelectScreensDialog } from '@/features/screens/components';
    import { useDelayedToggle, useDialogWidth } from '@/shared/hooks';
    import { computed, ref, toRef } from 'vue';
    import { SelectCreativeDialog } from '../dialogs';

    type Props = {
        orderId: string;
    };

    const props = defineProps<Props>();

    const modelValue = defineModel<boolean>();
    const dialogWidth = useDialogWidth();

    const { isLoading: orderLoading, order } = useEditableOrder(toRef(() => props.orderId));
    const { isLoading: orderCompanyLoading, company } = useCompany(toRef(() => order.value?.companyId));
    const { companySectors } = useCompanySectors(company);

    const isLoading = computed(() => orderLoading.value || orderCompanyLoading.value);
    const constrainToBudget = ref(false);

    const flightTime = computed(() => {
        if (!order.value) return null;

        return {
            startDate: new Date(order.value.startDate).getTime(),
            endDate: new Date(order.value.endDate).getTime(),
        };
    });

    const {
        maxPlays,
        minPlays,
        monthsExact,
        monthlyCost,
        rawMonthlyCost,
        hasBudget,
    } = useBudgetedOrderCostsAndPlays(toRef({
        companyId: toRef(() => company.value?.id),
        campaignId: toRef(() => order.value?.campaignId),
        screenIds: toRef(() => order.value?.screenIds),
        adDuration: toRef(() => order.value?.adDuration),
        playsPerMonth: toRef(() => order.value?.playsPerMonth),
        flightTime: flightTime,
        constrainToBudget: constrainToBudget,
    }));

    const [selectCreativeOpen, selectCreativeVisible] = useDelayedToggle();
    const [selectScreensOpen, selectScreensVisible] = useDelayedToggle();

    const { isLoading: saveLoading, mutateAsync, error } = useCommand('orders/EDIT_ORDER', {
        invalidates: ['campaigns/LIST_CAMPAIGNS', 'orders/'],
        onSettled(data, error) {
            if (!error) {
                modelValue.value = false;
            }
        },
    });

    function addCreative(creativeId: string) {
        if (!order.value) throw new Error('Order does not exist, cannot add creative');

        order.value.creativeIds.push(creativeId);
    }

    function removeCreative(creativeId: string) {
        if (!order.value) throw new Error('Order does not exist, cannot remove creative');

        order.value.creativeIds = order.value.creativeIds.filter((id) => id !== creativeId);
    }

    function removeScreen(screenId: number) {
        if (!order.value) throw new Error('Order does not exist, cannot remove screen');

        order.value.screenIds = order.value.screenIds.filter((id) => id !== screenId);
    }

    async function save() {
        if (!order.value) {
            console.error('Order not found, cannot edit order');
            return;
        }

        try {
            await mutateAsync({
                request: {
                    ...order.value,
                },
                params: {
                    orderId: props.orderId,
                },
            });
        } catch (e) {
            console.error('Error editing order', e);
            return;
        }
    }
</script>

<template>
    <SelectCreativeDialog
        v-if="order && selectCreativeVisible"
        v-model="selectCreativeOpen"
        :company-id="order.companyId"
        :exclude-ids="order.creativeIds"
        :duration-filter="order.adDuration"
        @selected="addCreative"
    />
    <SelectScreensDialog
        v-if="order && selectScreensVisible && companySectors"
        v-model="selectScreensOpen"
        v-model:screen-ids="order.screenIds"
        :exclude-sectors="companySectors"
    />
    <v-dialog
        v-model="modelValue"
        :width="dialogWidth"
        content-class="d-relative"
        max-height="80vh"
        height="80vh"
        persistent
    >
        <v-overlay contained :model-value="orderLoading" scrim="black" class="d-flex justify-center align-center">
            <v-progress-circular color="primary" indeterminate size="64" />
        </v-overlay>
        <v-card v-if="order" class="h-100 d-flex flex-column" :loading="isLoading || orderLoading">
            <perfect-scrollbar>
                <v-card-text>
                    <v-row justify="center">
                        <v-col cols="12" sm="12" md="11" lg="9">
                            <div class="truly-center--grid">
                                <div />
                                <span class="text-h2 text-center font-weight-medium">Edit Order: {{ order.name }}</span>
                                <v-checkbox
                                    v-if="hasBudget"
                                    v-model="constrainToBudget"
                                    hide-details
                                    color="orange"
                                    label="Budget Constrained"
                                    title="Budget constraints will not apply when creating this order."
                                />
                            </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="11" lg="9">
                            <v-row>
                                <v-col cols="12" sm="4" md="4" lg="4">
                                    <v-text-field v-model="order.name" label="Name" variant="outlined" dense />
                                </v-col>
                                <v-col cols="12" sm="8" md="8" lg="8">
                                    <v-text-field
                                        v-model="order.description"
                                        label="Description"
                                        variant="outlined"
                                        dense
                                    />
                                </v-col>
                                <v-col cols="12" sm="4" md="4" lg="4">
                                    <DatePicker v-model="order.startDate" label="Start Date" />
                                </v-col>
                                <v-col cols="12" sm="8" md="5" lg="5">
                                    <DatePicker v-model="order.endDate" :min="order.startDate" label="End Date" />
                                </v-col>

                                <v-col cols="12" sm="4" md="3" lg="3">
                                    <AdDurationDropdown v-model="order.adDuration" />
                                </v-col>

                                <v-col
                                    v-if="company?.companyType === 'SubCompany'"
                                    cols="12"
                                    sm="6"
                                    md="4"
                                    lg="4"
                                    class="my-2"
                                >
                                    <MaskedMoneyInput
                                        v-if="order.additionalLineItems"
                                        v-model="order.additionalLineItems.CreativeDesignFee"
                                        label="Creative Design Fee"
                                        hide-details
                                    />
                                    <v-alert v-else dense outlined color="error">
                                        Expected CreativeDesignFee Line Items. Got Nullish
                                    </v-alert>
                                </v-col>

                                <v-col cols="12" sm="12" class="d-flex justify-center align-center">
                                    <v-card variant="outlined" color="primary" rounded="sm" class="d-flex flex-grow-1">
                                        <v-card variant="tonal" color="primary" rounded="0">
                                            <v-card-text
                                                class="d-flex flex-column align-center justify-center text-h4 h-100 whitespace-break-spaces"
                                            >
                                                <div>{{ $filters.abbreviate(order.playsPerMonth) }} Plays</div>
                                                <div class="text-caption text-nowrap">
                                                    /month
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                        <v-card-text class="flex-nowrap d-flex align-center">
                                            <v-slider
                                                v-model="order.playsPerMonth"
                                                :disabled="!order.adDuration"
                                                :min="minPlays"
                                                :max="maxPlays"
                                                :step="1000"
                                                hide-details
                                                color="primary"
                                                class="plays-slider"
                                                :tick-size="3"
                                                show-ticks="always"
                                            >
                                                <template v-slot:prepend>
                                                    <span>{{ $filters.abbreviate(minPlays) }}</span>
                                                </template>

                                                <template v-slot:append>
                                                    <span>{{ $filters.abbreviate(maxPlays) }}</span>
                                                </template>
                                            </v-slider>
                                        </v-card-text>
                                        <v-card variant="flat" color="primary" rounded="0">
                                            <v-card-text class="d-flex align-center justify-center text-h3 h-100">
                                                ${{ $filters.number(monthlyCost) }}/m
                                            </v-card-text>
                                        </v-card>
                                    </v-card>
                                </v-col>

                                <v-col cols="12" class="d-flex">
                                    <v-expansion-panels class="locations-expansion rounded-sm rounded-e-0">
                                        <v-expansion-panel color="on-primary-fixed">
                                            <v-expansion-panel-title class="py-0 rounded-t-sm">
                                                <v-icon
                                                    icon="mdi-map-marker-multiple"
                                                    color="primary"
                                                    class="mr-2 ml-n3"
                                                />
                                                <v-card color="primary" variant="text" class="h-100 w-100">
                                                    <span class="text-h4">{{ order.screenIds.length }} Locations</span>
                                                </v-card>
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="bg-surface-container rounded-b-sm">
                                                <v-row>
                                                    <v-col
                                                        v-for="screenId in order.screenIds"
                                                        :key="screenId"
                                                        cols="12"
                                                        sm="6"
                                                        lg="4"
                                                    >
                                                        <SmallScreenCard
                                                            :screen-id="screenId"
                                                            can-remove
                                                            @remove="removeScreen(screenId)"
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                    <v-card variant="flat" color="primary" rounded="0" class="rounded-e-md">
                                        <v-card-text class="d-flex align-center justify-center text-h3 h-100 pa-0">
                                            <v-btn
                                                rounded="0"
                                                variant="flat"
                                                density="compact"
                                                size="large"
                                                block
                                                class="h-100 font-weight-bold"
                                                @click="selectScreensOpen = true"
                                            >
                                                Add
                                            </v-btn>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col cols="12" class="d-flex">
                                    <v-expansion-panels class="locations-expansion rounded-sm rounded-e-0">
                                        <v-expansion-panel color="on-primary-fixed">
                                            <v-expansion-panel-title class="py-0 rounded-t-sm">
                                                <v-icon
                                                    icon="mdi-file-video-outline"
                                                    color="primary"
                                                    class="mr-2 ml-n3"
                                                />

                                                <v-card color="primary" variant="text" class="h-100 w-100">
                                                    <span class="text-h4">{{ order.creativeIds.length }}
                                                        Creatives</span>
                                                </v-card>
                                            </v-expansion-panel-title>
                                            <v-expansion-panel-text class="bg-surface-container rounded-b-sm">
                                                <v-row>
                                                    <v-col
                                                        v-for="creativeId in order.creativeIds"
                                                        :key="creativeId"
                                                        cols="12"
                                                        sm="6"
                                                        lg="6"
                                                    >
                                                        <SmallCreativeCard
                                                            :creative-id="creativeId"
                                                            can-remove
                                                            @click:remove="removeCreative(creativeId)"
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-text>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                    <v-card variant="flat" color="primary" rounded="0" class="rounded-e-md">
                                        <v-card-text class="d-flex align-center justify-center text-h3 h-100 pa-0">
                                            <v-btn
                                                rounded="0"
                                                variant="flat"
                                                density="compact"
                                                size="large"
                                                block
                                                class="h-100 font-weight-bold"
                                                @click="selectCreativeOpen = true"
                                            >
                                                Add
                                            </v-btn>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="d-flex justify-center">
                                    <BillingMatrix :months="monthsExact" :raw-monthly-spend="rawMonthlyCost" />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </perfect-scrollbar>
            <v-spacer />
            <v-card-text class="flex-grow-0">
                <v-row>
                    <v-col cols="12" class="d-flex justify-center">
                        <RenderResultErrors :error="error" />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-btn color="grey" size="large" @click="() => (modelValue = false)">
                    Cancel
                </v-btn>
                <v-spacer />
                <v-btn color="primary" size="large" :loading="saveLoading" @click="save">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped lang="scss">
:deep(.v-expansion-panels.locations-expansion) {
    border: 2px solid rgb(var(--v-theme-primary-50));
}
</style>
