import type { VisibleListItemConfig } from '@/shared/types/uiConfigs';
import type { OrderRunErrorKind, OrderRunStatus } from '../api/orderRunsApiResponseModels';

const ERROR_ELEMENT = {
    color: 'red',
    icon: 'mdi-alert-circle-outline',
    text: 'Error',
};

const WARNING_ELEMENT = {
    color: 'orange',
    icon: 'mdi-alert-outline',
    text: 'Warning',
};

export const ORDER_RUN_PROGRESS_COLORS = {
    /** Order is on schedule, or close to on schedule */
    OnSchedule: 'green-darken-1',

    /** The Plays vs Flight is off by a significant amount */
    OffSchedule: 'error',
} as const;

export const ORDER_RUN_STATUS_UI_CONFIG = {
    None: {
        color: 'grey',
        icon: 'mdi-file-document-edit-outline',
        text: 'Draft',
    },
    Scheduled: {
        color: 'blue',
        icon: 'mdi-calendar-clock',
        text: 'Run Scheduled',
        description: 'The run is scheduled to start on {{startDate}}',
    },
    Running: {
        color: 'blue',
        icon: 'mdi-play-circle-outline',
        text: 'Running',
    },
    OnHold: {
        color: 'red',
        icon: 'mdi-hand-back-left',
        text: 'On Hold',
    },
    Error: {
        color: 'red',
        icon: 'mdi-alert-circle-outline',
        text: 'Error',
    },
    Completed: {
        color: 'green',
        icon: 'mdi-check-circle-outline',
        text: 'Completed',
    },
    Cancelled: {
        color: 'red-lighten-2',
        icon: 'mdi-close-circle-outline',
        text: 'Cancelled',
    },
    Terminated: {
        color: 'red',
        icon: 'mdi-close-octagon-outline',
        text: 'Terminated',
    },
} as const satisfies Record<OrderRunStatus, VisibleListItemConfig>;

export const ORDER_RUN_ERROR_KIND_UI_CONFIG = {
    None: {
        color: 'grey',
        icon: 'mdi-file-document-edit-outline',
        text: 'Draft',
    },
    MissingAdLogicLink: {
        ...ERROR_ELEMENT,
        description: 'Unable to locate run on screens. Please contact support.',
    },
    CannotFindAdLogicOrder: {
        ...ERROR_ELEMENT,
        description: 'Unable to locate run on screens. Please contact support.',
    },
    AdLogicUpdateFailed: {
        ...ERROR_ELEMENT,
        description: 'Failed to retrieve run status from screens. Please contact support.',
    },
    StateChangedActivated: {
        ...WARNING_ELEMENT,
        description: 'An unexpected state change occurred. Please contact support.',
    },
    StateChangedApproved: {
        ...WARNING_ELEMENT,
        description: 'An unexpected state change occurred. Please contact support.',
    },
    StateChangedDeactivated: {
        ...WARNING_ELEMENT,
        description: 'An unexpected state change occurred. Please contact support.',
    },
    StateChangedUnlocked: {
        ...WARNING_ELEMENT,
        description: 'An unexpected state change occurred. Please contact support.',
    },
} as const satisfies Record<OrderRunErrorKind, VisibleListItemConfig>;
