import { fillTemplate } from '@/shared/utilities';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import type { OrderRunErrorKind, OrderRunStatus, SimpleOrderRunResponse } from '../api/orderRunsApiResponseModels';
import { ORDER_RUN_ERROR_KIND_UI_CONFIG, ORDER_RUN_STATUS_UI_CONFIG } from '../config';

interface StatusConfig {
    color: string;
    icon: string;
    text: string;
    description?: string;
}

export function useOrderRunStatus(orderRun: Ref<Nullish<SimpleOrderRunResponse>>) {
    const configGrid = ref<Record<OrderRunStatus, StatusConfig>>(ORDER_RUN_STATUS_UI_CONFIG);
    const errorsConfigGrid = ref<Record<OrderRunErrorKind, StatusConfig>>(ORDER_RUN_ERROR_KIND_UI_CONFIG);

    const status = computed(() => orderRun.value?.status || 'None') as Ref<OrderRunStatus>;
    const configItem = computed(() => {
        if (status.value === 'Error' && orderRun.value!.errorState) {
            return errorsConfigGrid.value[orderRun.value!.errorState.kind];
        }
        return configGrid.value[status.value];
    });

    const color = computed(() => configItem.value.color);
    const icon = computed(() => configItem.value.icon);
    const text = computed(() => configItem.value.text);
    const desc = computed(() => {
        return fillTemplate(configItem.value.description || '', orderRun.value);
    });

    return {
        runStatus: status,
        runStatusUiConfig: configItem,
        runStatusColor: color,
        runStatusIcon: icon,
        runStatusText: text,
        runStatusDesc: desc,
    };
}
